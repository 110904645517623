<template>
    <div class="base-table-cell-info">{{ record_value }}</div>
</template>

<script>
import useRecordModel from "@common/composable/BaseTableData";
import {  computed } from 'vue';

export default {
    name: "CellInfo",
    props: {
        record: Object,
        column: Object
    },
    setup(props){
        const { getRecordValue } = useRecordModel();

        const record_value = computed(() => getRecordValue(props.record,props.column.key));

        return {
            record_value
        }
    }
}
</script>

<style scoped>
    .base-table-cell-info{
        /* height: 100%;
        width: 100%; */
        font-size: 0.8rem;
        /* border: 1px dotted black;
        border: 1px dotted var(--dark-color); */
        /* white-space: nowrap; */
        padding: 0 0.3rem;
    }
    .h-stretch .base-table-cell-info{
        white-space: normal;
    }

</style>
