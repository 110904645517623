<template>
    <div class="base-table-cell-label"><BaseIcon v-if="icon" :class="icon" />{{ column.content(record) }}</div>
</template>

<script>
import { computed } from 'vue';
export default {
    name: "CellLabel",
    props: {
        record: Object,
        column: Object
    },
    setup(props){
        const icon = computed(() => {
            if(Object.prototype.hasOwnProperty.call(props.column,'icon')){
                return props.column.icon(props.record)
            }
            return false;
        });
        
        return {
            icon
        }
    }
}
</script>

<style scoped>
.icon{
    width: 1rem;
    height: 1.5rem;
}
.base-table-cell-label{
    font-size: 0.8rem;
    padding: 0 0.3rem;
}


</style>
