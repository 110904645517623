<template>
    <tr class="base-table-row" :class="record.code">
        <td v-for="(column,index) in columns" :key="index" :title="getTitle(column)" :class="getClass(column)">
            <CellRowSelect v-if="column.type==='row_select'" :column="column" :record="record" />
            <CellEditRecord v-else-if="column.type==='edit_record'" :column="column" :record="record" />
            <CellDelete v-else-if="column.type==='delete' && record.code !== -1" :column="column" :record="record" />
            <CellSelectButton v-else-if="column.type==='select_button'" :column="column" :record="record" />
            <CellButton v-else-if="column.type==='button'" :column="column" :record="record" />
            <CellLabel v-else-if="column.type==='label'" :column="column" :record="record" />
            <CellInfo v-else-if="column.type==='info'" :column="column" :record="record" />
            <CellSelectValue v-else-if="column.type==='select'"  :column="column" :record="record" />
            <CellInput v-else-if="column.type==='text'" :column="column" :record="record" />
            <CellLoader v-else-if="column.type==='loader'" :column="column" :record="record" />
            <CellLink v-else-if="column.type==='link'" :column="column" :record="record" />
        </td>
    </tr>
</template>

<script>
import CellLabel from '@common/components/crud/cells/CellLabel';
import CellSelectValue from '@common/components/crud/cells/CellSelectValue';
import CellSelectButton from '@common/components/crud/cells/CellSelectButton';
import CellRowSelect from '@common/components/crud/cells/CellRowSelect';
import CellInput from '@common/components/crud/cells/CellInput';
import CellEditRecord from '@common/components/crud/cells/CellEditRecord';
import CellButton from '@common/components/crud/cells/CellButton';
import CellInfo from '@common/components/crud/cells/CellInfo';
import CellDelete from '@common/components/crud/cells/CellDelete';
import CellLoader from '@common/components/crud/cells/CellLoader';
import CellLink from '@common/components/crud/cells/CellLink';

export default {
    name: "BaseTableRow",
    components: {
        CellLabel,
        CellSelectValue,
        CellSelectButton,
        CellRowSelect,
        CellInput,
        CellEditRecord,
        CellButton,
        CellInfo,
        CellDelete,
        CellLoader,
        CellLink,
    },
    props: {
        columns: Array,
        record: Object,
    },
    setup(props){
        const getTitle = (column) => {
            if(Object.prototype.hasOwnProperty.call(column,'title')){
                return column.title;
            }
            if(Object.prototype.hasOwnProperty.call(column,'getTitle')){
                return column.getTitle(props.record);
            }
            return "";
        };

        const getClass = (column) => {
            let array = [];

            if(Object.prototype.hasOwnProperty.call(column,'class')){
                array.push(column.class);
            }
            if(column.type === 'loader'){
                array.push('loader');
            }
            if(Object.prototype.hasOwnProperty.call(column,'getClass')){
                array.push(column.getClass(props.record));
            }
            return array;
        }

        return {
            getTitle,
            getClass
        }
    }
}
</script>

<style scoped>
    .base-table-row {
        text-align: center;
        position: relative;
    }
    .base-table-row td{
        height: 1.5rem;
    }
    .base-table-row td>*{
        height: 100%;
        overflow: hidden;
        height: 1.5rem;
        line-height: 1.5rem;
    }
    .base-table-row button {
        width: 1.5rem;
        /* height: 1.5rem; */
        padding: 0.3rem;
        display: block;
        border-radius: 50%;
    }
    .base-table-row select,.base-table-row input,.base-table-row .base-table-cell-info{
        width: 6rem;
    }
    .base-table-row .w-xs>*{
        width: 1.5rem;
    }
    .base-table-row .w-s>*{
        width: 3rem;
    }
    .base-table-row .w-l>*{
        width: 8rem;
    }
    .base-table-row .w-xl>*{
        width: 10rem;
    }
    .base-table-row .w-xxl>*{
        width: 15rem;
    }
    .base-table-row .w-stretch>*{
        width: initial;
    }
    .base-table-row .w-stretch{
        width: 100%;
    }
    .base-table-row .h-stretch{
        white-space: normal;
        height: initial;
    }
    .base-table-row .h-stretch>*{
        height: initial;
    }

    .base-table-row .align-left{
        text-align: left;
    }
    .base-table-row .align-right{
        text-align: right;
    }

    .base-table-row:hover,.base-table-row:focus-within{
        filter: contrast(1.1);
    }
    
    .base-table-row td.loader{
        padding: 0;
        position: relative;
        left: 0;
        right: 0;
        text-align: center;
    }
    .base-table-row .strong{
        font-weight: 700;
    }

</style>
