<template>
    <select class="select" v-model.lazy="model.value">
        <option v-if="column.empty" value=""> </option>
        <option class="option" v-for="(option,index) in options.value" :key="index" :value="code(option)" :title="getTitle(option)">
            {{ option[column.options[0]] }}
        </option>
    </select>
</template>

<script>
import { computed } from 'vue';
import useRecordModel from "@common/composable/BaseTableData";

export default {
    name: "CellSelectValue",
    props: {
        record: Object,
        column: Object,
    }, 
    setup(props){
        const { getModel,getOptions } = useRecordModel();

        const options = computed(() => getOptions(props.column));
        const model = computed(() => getModel(props.record,props.column));

        const getTitle = (option) => {
            let title = '';
            props.column.options.forEach(element => {
                title += option[element] + " | ";
            });
            return title;
        };
        const code = (option) => {
            return option[props.column.code];
        };

        return {
            options,
            model,
            getTitle,
            code
        }
    }
}
</script>

<style scoped>
</style>
