<template>
    <tr class="base-table-header">
        <th v-for="(column,index) in columns" :key="index" :class="column.class" rowspan="0">
            <div v-if="column.hasOwnProperty('header')" :title="title(column)" >
                {{ column.header }}
            </div>
            <input v-if="column.hasOwnProperty('filter') && column.filter !== false" :title="column.title" v-model.lazy="filter_models[column.key].value"/>
            <div v-if="column.type==='select_button'">
                <button type="button"><BaseIcon class="check" /></button>
                <button type="button"><BaseIcon class="close" /></button>
            </div>
            <select v-if="column.hasOwnProperty('filter_select') && column.filter !== false" :title="column.title" class="filter_select" v-model.lazy="filter_models[column.key].value">
                <option value=""> </option>
                <option class="option" v-for="(option,index) in getFilterOptions(column).value" :key="index" :value="option.code" :title="option.title">
                    {{ option.title }}
                </option>
            </select>
        </th>
    </tr>
</template>

<script>
import useRecordModel from "@common/composable/BaseTableData";
import { computed } from 'vue';

export default {
    name: "BaseTableHeader",
    props: {
        columns: Array,
    },
    setup(props) {

        const { getFilterModels,getFilterOptions } = useRecordModel();
        
        const filter_models = computed(() => getFilterModels(props.columns));

        const title = (column) => {
            if(column.header_title){
                return column.header_title;
            }
            return column.header;
        }
        
        return {
            filter_models,
            getFilterOptions,
            title
        }
    }
}
</script>

<style scoped>
    .base-table-header select,.base-table-header input{
        width: 6rem;
    }
    .base-table-header .w-xs>*{
        width: 1.5rem;
    }
    .base-table-header .w-s>*{
        width: 3rem;
    }
    .base-table-header .w-xl>*{
        width: 10rem;
    }
    .base-table-header .w-xxl>*{
        width: 15rem;
    }
    .base-table-header .w-l>*{
        width: 8rem;
    }
    .base-table-header th{
        font-size: 0.8rem;
        width: 100%;
    }

</style>