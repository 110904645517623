<template>
    <input :type="column.type" v-model.lazy="model.value" :disabled="column.disabled===true"/>
</template>

<script>
import { computed } from 'vue';
import useRecordModel from "@common/composable/BaseTableData";

export default {
    name: "CellInput",
    props: {
        record: Object,
        column: Object
    },
    setup(props){
        const { getModel } = useRecordModel();
        
        const model = computed(() => getModel(props.record,props.column));

        return {
            model
        }
    }
}
</script>

<style scoped>

</style>
