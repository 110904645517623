<template>
    <BaseButton class="edit-record" type="button" :title="$t('controls.edit_record')" @click="column.action(record)"><BaseIcon class="pen" /></BaseButton>
</template>

<script>
export default {
    name: "CellEditRecord",
    props: {
        record: Object,
        column: Object
    }
}
</script>

<style scoped>

</style>
