<template>
    <button type="button"></button>
</template>

<script>
export default {
    name: "CellRowSelect",
    props: {
        record: Object,
        column: Object
    }
}
</script>

<style scoped>

</style>
