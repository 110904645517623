<template>
    <BaseButton type="button" @click="column.action(record)"><BaseIcon v-if="icon" :class="icon" />{{ column.content(record) }}</BaseButton>
</template>

<script>
import { computed } from 'vue';
export default {
    name: "CellButton",
    props: {
        record: Object,
        column: Object
    },
    setup(props){
        const icon = computed(() => {
            if(Object.prototype.hasOwnProperty.call(props.column,'icon')){
                return props.column.icon(props.record)
            }
            return false;
        });
        
        return {
            icon
        }
    }
}
</script>

<style scoped>

</style>
