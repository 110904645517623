<template>
    <BaseButton class="btn-danger edit-record" type="button" :title="$t('controls.delete_record')" @click="delete_record"><BaseIcon class="close_white" /></BaseButton>
</template>

<script>
import { inject } from 'vue';
import { useStore } from 'vuex'

export default {
    name: "ModuleTableCellDelere",
    props: {
        record: Object,
        column: Object
    },
    setup(props){
        const crud_store = inject('crud_store');

        const store = useStore();
        // todo - add confirmation form
        const delete_record = () => store.dispatch(crud_store+'/deleteItem',props.record);

        return {
            delete_record
        }
    }
}
</script>

<style scoped>

</style>
