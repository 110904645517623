<template>
    <BaseLoader v-if="record.updating === 1" />
</template>

<script>
export default {
    name: "CellLabel",
    props: {
        record: Object,
        column: Object
    }
}
</script>

<style scoped>
    .loader .base-loader{
        position: absolute;
        left: -0.5rem;
        top: 0.75rem;
        width: 1rem;
        height: 1rem;
    }

</style>
