<template>
    <table class="base-table" ref="this_table">
        <thead v-if="header">
            <BaseTableHeader :columns="columns" />
        </thead>
        <tbody ref="tbody">           
            <BaseTableRow 
                v-for="(record, index) in data" :key="index" 
                :columns="columns" 
                :record="record"
                @click="$emit('select',$event,index)" 
                :class="[{selected: selected_record && selected_record.code === record.code},rowClass != null ? rowClass(record) : '']"

            />
        </tbody>
    </table>
    <div class="stretch"></div>
</template>

<script>
import BaseTableHeader from "./BaseTableHeader";
import BaseTableRow from "./BaseTableRow";
import useRecordModel from "@common/composable/BaseTableData";

export default {
    name: "BaseTable",
    components: {
        BaseTableHeader,
        BaseTableRow
    },
    props: {
        data: Array,
        columns: Array,
        header: {
            type: Boolean,
            default: () => true
        },
        rowClass: {
            type: Function,
            default: () => null
        },
    },
    emits: ['select'],
    setup() {
        const { selected_record } = useRecordModel();
        
        return {
            selected_record,
        }
    }

}
</script>

<style scoped>
    .base-table ::v-deep(input) {
        width: 6rem;
    }
    .base-table{
        width: fit-content;
        border-collapse: collapse;
        overflow-y: auto;
        position: relative;
        min-width: calc(100% - 2rem);
    }
    .base-table ::v-deep(th){
        position: sticky;
        top: 0;
        background: white;
        padding-bottom: 0.2rem;
        /* border-bottom: 1px solid lightslategray; */
        box-shadow: inset 0 -1px 0 #dcd6cb;
        width: 1px;
        vertical-align: bottom;
    }
    .base-table tbody{
        height: 1px;
    }

    .loader{
        text-align: center;
    }
    
    .stretch{
        flex-grow: 1;
    }

    @media screen and (max-width: 600px) {
        /* .base-table {
            width: calc(100% - 2rem);
        }
        table.base-table, .base-table thead, .base-table tbody, .base-table ::v-deep(th),.base-table ::v-deep(td) , .base-table ::v-deep(tr)  { 
            display: block; 
        } */
    }


</style>>
