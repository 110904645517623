
import { computed,inject } from 'vue';
import { useStore } from 'vuex';

export default function() {
    const crud_store = inject('crud_store');

    const store = useStore();

    // const getter_setter = (value,key,record) => {
    //     return {
    //         get () {
    //             return value;
    //         },
    //         set (newValue) {
    //             store.dispatch(crud_store+'/updateItem',{data: {[key]:newValue},record});
    //         }
    //     }
    // }

    
    const getter_setter = (key,record) => {
        let keys = key.split('.');
        let len = keys.length;
        if(len < 1){
            return {};
        }
        let record_part = record;
        for(let i = 0; i < len-1; i++) {
            let elem = keys[i];
            if( !record_part[elem] ){
                record_part[elem] = {}
            } 
            record_part = record_part[elem];
        }
        return {
            get () {
                return record_part[keys[len-1]];
            },
            set (newValue) {
                let record_update = {
                    [keys[len-1]]: newValue,
                };
                for(let i = len-2; i >= 0; i--){
                    let temp = {
                        [keys[i]]: record_update,
                    }

                    record_update = {...temp};
                }
                console.log(record_update)

                store.dispatch(crud_store+'/updateItem',{data: record_update,record});
            }
        }
    }
    
    const filter_getter_setter = (value,key,exact) => {
        return {
            get () {
                return value;
            },
            set (newValue) {
                store.dispatch(crud_store+'/filter', {key: key,value: newValue,exact});
            }
        }
    }

    const getModel = (record,cell) => {
        if(!Object.prototype.hasOwnProperty.call(cell,'key')){
            return "";
        }
        const key = cell.key;
        let get_set = getter_setter(key,record);
        // if(Object.prototype.hasOwnProperty.call(record,key)){
        //     get_set = getter_setter(record[key],key,record);
        // } else {
        //     get_set = getter_setter("",key,record);
        // } 
        return computed(get_set);
    }

    const filters = computed(() => store.getters[crud_store+'/getFilters']);

    const getFilterModel = (column) => {
        let key = column.key;
        // const filter_key = key;
        if(Object.prototype.hasOwnProperty.call(column,'filter_key')){
            key = column.filter_key;
        }    
        let exact = false
        if(Object.prototype.hasOwnProperty.call(column,'filter_exact')){
            exact = column.filter_exact;
        }           
        let get_set = '';
        if(Object.prototype.hasOwnProperty.call(filters.value,key)){
            get_set = filter_getter_setter(filters.value[key].value,key,exact);
        } else {
            get_set = filter_getter_setter("",key,exact);
        } 
        return computed(get_set);
    }

    const getFilterModels = (columns) => {
        let list = [];
        columns.forEach(column => {
            if(!Object.prototype.hasOwnProperty.call(column,'key')){
                return;
            }
            list[column.key] = getFilterModel(column);
        });
        return list;
    }

    const getOptions = (cell) => {
        if(!Object.prototype.hasOwnProperty.call(cell,'options_store')){
            return false;
        }
        return computed(() => {
            let options = store.getters[cell.options_store];
            if(!options){
                return []
            }
            // console.log(options)
            return options;
        });
    }
    const getFilterOptions = (column) => { 
        return computed(() => {
            if(Array.isArray(column.filter_select))
                 return column.filter_select;
        
            if(!Object.prototype.hasOwnProperty.call(column,'filter_code') || !Object.prototype.hasOwnProperty.call(column,'filter_title')){
                return [];
            }    
            let options = getOptions(column);
            let filterOptions = [];
            options.value.forEach((option) => {
                filterOptions.push({code: option[column.filter_code], title: option[column.filter_title]});
            });
            // console.log(filterOptions)
            return filterOptions;
        });
    }

    const getRecordValue = (record,key) => {
        if(!key){
            return "";
        }
        //  console.log(record)
        let keys = key.split('.');
        let record_part = record;
        // console.log(keys)
        keys.forEach(element => {
            if(typeof record_part === 'object' && record_part !== null && Object.prototype.hasOwnProperty.call(record_part,element)){
                record_part = record_part[element];
            }
        });
        if(typeof record_part !== 'object' ){
            return record_part;
        }
        return '';
    }

    const selected_record = computed(() => {
        return store.getters[crud_store+'/getSelectedRecord'];
    });

    
    const pageCount = computed(() => store.getters[crud_store+'/getPageCount']);
    const currentPageNum = computed(() => store.getters[crud_store+'/getCurrentPageNum']);

    // for scrolling
    // const last_viewed_row_code = computed(() => {
    //     return store.getters[crud_store+'/getLastViewedRowCode'];
    // })

    // const setLastViewedRowCode = (code) => {
    //     store.commit(crud_store+'/setLastViewedRowCode',code);
    // }

    // const isFullyLoaded = computed(() => {
    //     return store.getters[crud_store+'/isFullyLoaded'];
    // })

    // const fetchNextPage = () => {
    //     store.dispatch(crud_store+'/fetchNextPage');
    // }

    return {
        getModel,
        getOptions,
        getRecordValue,
        selected_record,
        // last_viewed_row_code,
        // setLastViewedRowCode,
        // isFullyLoaded,
        // fetchNextPage,
        getFilterModels,
        getFilterOptions,
        pageCount,
        currentPageNum
    }

}
